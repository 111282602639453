import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchChapter, publishedChapter, regenerateChapterMedia } from "../api";
import config from "../config";
import LoaderIcon from "./LoaderIcon";
import "./storyPerChapter.css";
import TextToParagraph from "./TextToParagraph";
import useToast from './../hooks/notificationHooks'
import Toasts from "./toasts";

const generateRandomHash = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const StoryPerChapter = () => {
  let { chapter_id } = useParams();
  const [notification, setNotification] = useState({messages: '', type: ''})
  const [chapter, setChapter] = useState([]);
  const [apiReqestInProgress, setApiReqestInProgress] = useState(false);
  const [isImageGenerated, setIsImageGenerated] = useState(false)
  const navigate = useNavigate();
  const backToStory = (storyId) => {
    navigate("/story/" + storyId);
  };

  useEffect(() => {
    const getChapter = async (chapter_id) => {
      const { data } = await fetchChapter(chapter_id);
      setChapter(data);
    };
    getChapter(chapter_id);
  }, [chapter_id]);

  const published = async () => {
    publishedChapter(chapter_id, false).then((response)=> {
      if (response.data) {
        setNotification({messages: response.data.chapter_title + " successfully published", type: 'success'})
        setChapter(response.data); // Update your state with the new chapter data
      }

    }, (error)=> {
      setNotification({messages: "Oops! somethign went wrong", type: 'error'})
    }); // Assuming chapter.id is your chapter_id
  };

  const onClickRegenerateMedia = async () => {
    setApiReqestInProgress(true);
    regenerateChapterMedia(chapter_id, 1).then((response)=> {
      const data = response?.data;
      if (data) {
        const tempchapter = chapter;
        tempchapter.media = [];
        setChapter(tempchapter);
        setChapter(data);
        setApiReqestInProgress(false);
        setIsImageGenerated(true)
        setNotification({messages: "Image successfully regenerated", type: 'success'})
      }
    }, (error)=> {
      setNotification({messages: "Oops! somethign went wrong", type: 'error'})
    })    
  };

  const resetMessage = ()=>{
    setNotification({messages: '', type: ''})
  }

  return (
    <>
      <section
        data-bs-version="5.1"
        className="article8 cid-u40jhaff9J"
        id="article08-1v"
      >
        <div className="container mt-5">
          <div className="perstory">
            <div className="singlestory_img">
              {apiReqestInProgress && (
                <div className="loaderWrapper">
                  <LoaderIcon />
                </div>
              )}
              {chapter.media && !apiReqestInProgress && (
                <img
                  src={`${chapter.media[0]?.image_file_url}`}
                  alt="Mobirise Website Builder"
                  className="animate__animated animate__delay-1s animate__fadeIn"
                />
              )}
            </div>

            <div className="story_content">
              <div className="card-content-text p-0">
                <h3 className="card-title mbr-fonts-style mbr-white mt-3 mb-4 display-2 animate__animated animate__delay-1s animate__fadeIn">
                  <strong>{chapter.chapter_title}</strong>
                  
                </h3>
                <div className="d-lg-flex justify-content-center gap-3">
                  {chapter.draft_mode && (
                    <>
                      {!isImageGenerated && <button
                        class="publish_btn"
                        onClick={() => onClickRegenerateMedia()}
                      >
                         <span>
                          
                        Regenerate Image
                          </span>
                      </button>}
                      <button
                        class="publish_btn"
                        onClick={() => published(chapter.id)}
                      >
                         <span>
                        Publish                          
                          </span>
                      </button>

                      <button
                        type="button"
                        class="publish_btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => backToStory(chapter.story)}
                      >
                        <span>

                        Back To Story
                        </span>
                      </button>
                    </>
                  )}
                </div>
                <div className="rosw czard-box align-left">
                  <div className="item features-without-image col-12 active animate__animated animate__delay-1s animate__fadeIn">
                    <div className="item-wrapper">
                      <div className="mbr-text mbr-fonts-style display-7 fullstory_text">                       
                        <TextToParagraph text={chapter.content} />
                      </div>
                    </div>
                  </div>
                </div>
                {!chapter.draft_mode && (
                  <div
                    className="row"
                    style={{ display: "flex", justifyContent: "center",width:"50%",margin:"0 auto" }}
                  >
                    <button
                      type="button"
                      class="publish_btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => backToStory(chapter.story)}
                    >
                      {" "}
                      <span>
                          
                      Back To Story
                          </span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toasts messages={notification.messages} type={notification.type} resetMessage={resetMessage}/>
    </>
  );
};

export default StoryPerChapter;
