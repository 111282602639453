import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
import "./ParentsReg.css";
import axios from "axios";
import { userRegister } from "./../api";
// // import Toasts from "../components/toasts";
let confirmPassword = document.querySelector("#confirmPassword");

const generateRandomText = (total_length) => {
  const hex = "0123456789abcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < total_length; i++) {
    result += hex[Math.floor(Math.random() * 36)];
  }
  return result;
};
const ParentsReg = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(token ? true : false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [acceptCheckBox, setAcceptCheckBox] = useState(false);

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  });
  let username;
  const [inputValue, setInputValue] = useState({
    email: "",
    user_name: username,
    phone_number: "",
    confirmPassword: "",
    password: "",
    firstname: null,
    lastname: null,
  });
  const userEmail = inputValue.email;
  username = userEmail.substring(0, 5);
  inputValue.user_name = username + generateRandomText(6);
  console.log(inputValue.user_name);

  const [didEdit, setDitEdit] = useState({
    email: false,
    user_name: false,
    phone_number: false,
    confirmPassword: false,
    password: false,
  });
  const emailIsInvalid = didEdit.email && !inputValue.email.includes("@");
  const [error, setError] = useState({});
  const getData = (e) => {
    const { value, name } = e.target;
    setInputValue(() => {
      return {
        ...inputValue,
        [name]: value,
      };
    });
    setDitEdit((prevEdit) => ({
      ...prevEdit,
      [name]: false,
    }));
  };

  // Checkbox change handler
  const handleCheckboxChange = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.checked, // Update the checkbox state
    });
    setAcceptCheckBox(false); // Hide error when the checkbox is checked
  };

  const register = (e) => {
    e.preventDefault();
    const {
      email,
      password,
      confirmPassword,
      dateOfBirth,
      user_name,
      phone_number,
    } = inputValue;
    console.log(inputValue);

    if (inputValue.password !== inputValue.confirmPassword) {
      setPasswordError(true); // Set an error state if passwords don't match
      return; // Prevent form submission
    }
    if (!inputValue.newslater) {
      setAcceptCheckBox(true); // Show error if checkbox is not selected
      return;
    }

    // axios
    //   .post(
    //     "https://dev-kidsplanet.impressicocrm.com/api/user/register/",
    //     inputValue
    //   )
    userRegister(inputValue)
      .then((res) => {
        setLoading(true);
        console.log(res.data.user_id);
        console.log(res.status);
        if (res.status === 200) {
          navigate("/Otp");
          localStorage.setItem("user_id", res.data.user_id);
          // toast.success("successful");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setError({ message: error.response.data.error })
        // toast.success(error.message);
      });
  };
  const handleInputFocus = () => {
    setPasswordError(false); // Clear the password error when focused
    setPhoneError(false)
  };


  const handleInputBlur = (e) => {
    const { name } = e.target;
    if (name === "phone_number") {
      const isValidPhone = /^\d{10}$/.test(inputValue.phone_number);
      setPhoneError(!isValidPhone); // Set error if not a valid phone number
    }
    setDitEdit((prevEdit) => ({
      ...prevEdit,
      [name]: true,
    }));
  };

  return (
    <>
      <section className="loginSingupWapper">
        <div className="container">
          {/* <div className="img d-none d-lg-block">
                <img src="https://t3.ftcdn.net/jpg/05/17/31/54/360_F_517315479_5APCsHqS3fFO0NAHewzDu9XS8Y2PhLBy.jpg" alt="" />
                <img className="screw" src="https://i.postimg.cc/y6PttJ6H/screw.png" alt="" />
                </div> */}
          <div className="d-flex align-items-center full-height">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <img
                  className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                  src={`${process.env.PUBLIC_URL}/images/signup.svg`}
                  alt="Mobirise Website Builder"
                />
              </div>
              <div className="col-12 col-lg-6">
                <div className="signup">
                  <div className="overlay">
                    <h3>
                      <strong>Sign Up</strong>
                      Explore Our Enchanted Library
                    </h3>
                    {/* {error && (
                    <div class="alert alert-danger" role="alert">
                      {<p className="mb-0">{error.response}</p>}
                    </div>
                  )} */}
                  {error.message && (
                   <div className="alert alert-danger" role="alert">
                       <p className="mb-0">{error.message}</p>
                    </div>
                    )}
                    <div className="d-none">
                      <div className="signUpButton">
                        <button className="fb-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="facebook"
                          >
                            <path d="M15 4h3a.5.5 0 0 0 .5-.5v-3A.5.5 0 0 0 18 0h-3a5.506 5.506 0 0 0-5.5 5.5V9H6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3.5v10.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V13H17a.502.502 0 0 0 .475-.342l1-3A.501.501 0 0 0 18 9h-4.5V5.5c0-.827.673-1.5 1.5-1.5z"></path>
                          </svg>
                          <strong>Facebook</strong>
                        </button>
                        <button className="apple-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            id="apple"
                          >
                            <path d="M20.7 16.8c-.3-.1-2.5-1.1-2.5-3.9 0-2.2 1.5-3.3 2.1-3.7.1-.1.2-.2.2-.3 0-.1 0-.3-.1-.4-1.4-2.1-3.6-2.3-4.2-2.3-1.1-.1-2.2.3-3 .6-.4.2-.8.3-1.1.3-.3 0-.7-.2-1.1-.3-.7-.3-1.5-.6-2.4-.6-1.9 0-3.8 1.2-4.8 2.9-1.8 3.5-.6 8.5 1.4 11.5.8 1.2 2 2.9 3.7 2.9H9c.7 0 1.2-.2 1.7-.5.5-.2 1-.4 1.7-.4s1.2.2 1.6.4c.5.2 1 .4 1.8.4 1.7 0 2.8-1.6 3.6-2.9.9-1.3 1.4-2.7 1.5-3.2.2-.1.1-.4-.2-.5zM14.9 3.3c.7-.8 1.1-1.8 1.1-2.8-.9.2-1.9.8-2.5 1.5-.5.6-1.1 1.7-1.1 2.8.9-.1 1.9-.7 2.5-1.5z"></path>
                          </svg>
                          <strong>Apple</strong>
                        </button>
                        <button className="google-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            id="google"
                          >
                            <path
                              fill="#fbbb00"
                              d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
                            ></path>
                            <path
                              fill="#518ef8"
                              d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
                            ></path>
                            <path
                              fill="#28b446"
                              d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
                            ></path>
                            <path
                              fill="#f14336"
                              d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
                            ></path>
                          </svg>
                          <strong>Google</strong>
                        </button>
                      </div>
                      <div className="or">
                        <span>or</span>
                      </div>
                    </div>
                    <form action="#">
                      <div className="inp-wrapper d-none">
                        <input
                          name="user_name"
                          required
                          onChange={getData}
                          type="email"
                          id="user_name"
                          className="form-control"
                          placeholder="user_name"
                          maxlength="50"
                          onBlur={handleInputBlur}
                        />
                      </div>

                      <div className="inp-wrapper">
                        <input
                          name="email"
                          required
                          onChange={getData}
                          type="email"
                          id="email"
                          className={`form-control ${
                            emailIsInvalid ? "error" : ""
                          }`}
                          placeholder="Email Address"
                          maxlength="50"
                          onBlur={handleInputBlur}
                        />
                        {emailIsInvalid && (
                          <div className="error-msg d-flex justify-content-between">
                            <p id="errorMeg">invalid email</p>
                          </div>
                        )}
                      </div>

                      <div className="inp-wrapper">
                        <input
                          name="password"
                          required
                          onChange={getData}
                          onFocus={handleInputFocus}
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          maxlength="50"
                        />
                        {/* <div className="error-msg d-flex justify-content-between">
                          <p>Please enter a valid password</p>
                        </div> */}
                      </div>
                      <div className="inp-wrapper">
                        <input
                          name="confirmPassword"
                          required
                          onChange={getData}
                          type="password"
                          id="confirmPassword"
                          className="form-control"
                          placeholder="Confirm Password"
                          maxlength="50"
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                        />
                        {/* Display error if passwords do not match */}
                        {passwordError && (
                          <div className="error-msg d-flex justify-content-between">
                            <p style={{ color: "red" }}>Passwords do not match</p>
                          </div>
                        )}
                      </div>

                      <div className="inp-wrapper">
                        <input
                          name="phone_number"
                          required
                          onChange={getData}
                          onFocus={handleInputFocus}
                          type="tel"
                          id="phone_number"
                          className="form-control"
                          placeholder="phone number"
                          maxlength="50"
                          onBlur={handleInputBlur}
                        />
                      {phoneError && (
                          <div className="error-msg d-flex justify-content-between">
                            <p style={{ color: "red" }}>Phone number must be exactly 10 digits</p>
                          </div>
                        )}
                      </div>
                      <div className="imp-wrapper firstnamelastname d-none">
                        <div className="d-flex">
                          <input
                            type="text"
                            id="firstname"
                            placeholder="First Name"
                            name="firstname"
                          />
                          <input
                            type="text"
                            id="lastname"
                            placeholder="Last Name"
                            minLength={8}
                            maxLength={20}
                            name="lastname"
                          />
                        </div>
                      </div>
                      <div className="dateOfBirth imp-wrapper">
                        <input
                          type="date"
                          name="dateOfBirth"
                          id="dob"
                          onChange={getData}
                        />
                      </div>
                      <div className="trems_tags mb-5">
                        <div className="tacbox d-flex align-items-baseline mb-2">
                          <input
                            id="T&C"
                            type="checkbox"
                            value={true}
                            name="newslater"
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="T&C">
                            {" "}
                            I agree to receive marketing communications
                            according to the <a href="#">Privacy Policy</a> and
                            agree to the Tricentis{" "}
                            <a href="#">Terms and Conditions</a>.
                          </label>
                        </div>
                        {acceptCheckBox && (
                          <div className="error-msg d-flex justify-content-between">
                            <p style={{ color: "red" }}>Please accept Privacy Policy andTerms and Conditions </p>
                          </div>
                        )}
                        <div className="tacbox d-flex">
                          <input id="news" type="checkbox" />
                          <label htmlFor="news">
                            {" "}
                            Send me newsletten and keep me update on daily basis
                          </label>
                        </div>

                      </div>

                      <div className="d-flex justify-content-center createBtn_wapper">
                        <button
                          type="submit"
                          onClick={register}
                          className="btn btn-black createBtn"
                        >
                          {" "}
                          <span>Agree &amp; Sign Up </span>
                        </button>
                      </div>
                    </form>
                    <div className="haveAccount">
                      Already have an account? <Link to="/login">Sign In</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ParentsReg;
