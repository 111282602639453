import React from "react";
import "./searchStory.css";
import config from "../../src/config";
import { Link } from "react-router-dom";

const SearchStory = (props) => {
const { API_BASE_URL } = config;
  const { results, enableSearchBar, onClickShowSuggsetion } = props;
  return (
    <div className="container customHeight">
      {enableSearchBar && <div className="row">
        <div className="col-lg-12 card-margin">
          <div className="card search-form">
            <div className="card-body p-0">
              <form id="search-form">
                <div className="row">
                  <div className="col-12">
                    <div className="row no-gutters">
                      <div className="col-lg-8 col-md-6 col-sm-12 p-0">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="form-control"
                          id="search"
                          name="search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>}
      <div className="row">
        <div className="col-12">
          <div className="card card-margin overflow">
            <div className="card-body">
                {onClickShowSuggsetion && <button className="btn btn-primary" onClick={()=>onClickShowSuggsetion? onClickShowSuggsetion(false):""}>Hide Suggestion</button>}
              <div className="row search-body">
                <div className="col-lg-12">
                  <div className="search-result">                    
                    <div className="result-body">
                      <div className="table-responsive">
                        <table className="table widget-26">
                          <tbody>
                            {results &&
                              results.map((story) => (
                                <tr>
                                <td>
                                  <div className="widget-26-job-emp-img">
                                    {/* Image code */}
                                    <img
                                      className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                                      src={`${process.env.PUBLIC_URL}/images/bookCover.png`}
                                      alt="Mobirise Website Builder"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="widget-26-job-title">
                                    <Link to={'/story/' + story.id}>{story.title}</Link>
                                    <div className="story-details">
                                      <span className="story-genre">{story.genre}</span>
                                      {story.age && <span> | <span className="story-age">{story.age}</span></span>}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchStory;
