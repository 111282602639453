import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { APPROVED, REJECTED, PUBLISHED, PUBLISH_READY, DEFAULT_PAGE_SIZE, REVIEW_READY, DELETED } from '../constants/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCancel, faArrowUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import './userStories.css';
import { fetchUserStories, changeStatus } from "./../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './../components/AuthContext';

const ParentStoryList = ({ onStoriesLoad, profileId }) => {
    const [stories, setStories] = useState([]);
    const [currentPage, setPage] = useState(1);
    const navigate = useNavigate();
    const { logout } = useAuth();

    const fetchStories = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const queryParams = {
            page: currentPage,
            page_size: DEFAULT_PAGE_SIZE
        };
        // const profileId = localStorage.getItem('profile_id');

        fetchUserStories(queryParams, profileId)
            .then(response => {
                setStories(response.data.results);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/login');
                    } else {
                        console.error('API Error:', error.response);
                    }
                } else {
                    console.error('Unexpected Error:', error.message || error);
                }
            });

    };

    useEffect(() => {
        fetchStories();
    }, [currentPage]);

    const updateStoryStatus = (id, status) => {
        changeStatus(id, status).then(response => {
            if (response.status === 200 && stories.length > 0) {
                console.log('updateStoryStatus successfully', response.data.success)
                if (status === PUBLISHED) {
                    toast.success("Your story status has been updated to Published. Congratulations!");
                }
                else {
                    toast.success(`Your story status has been updated to ${status.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ')}`);

                }
                updateStoryUi(id, status);
            }
        }).catch(error => {
            console.error('There was an error!', error);
            toast.error('Failed to update the story status.');
            if (error.response.status === 401) {
                logout();
                navigate('/login');
            }
        });
    };

    const updateStoryUi = (id, newStatus) => {
        setStories((prevStories) =>
            prevStories.map((story) =>
                story.id === id ? { ...story, status: newStatus } : story
            )
        );
    };

    const approveDeleteReject = (data) => {
        const isApproveEnabled = data.status !== PUBLISHED && data.status !== APPROVED && data.status !== PUBLISH_READY && data.status !== REVIEW_READY && data.status !== REJECTED && data.status !== DELETED;
        const isRejectEnabled = data.status !== REJECTED && data.status !== PUBLISHED && data.status !== DELETED;
        const isPublishEnabled = data.status === PUBLISH_READY;

        return (
            <div key={data.status} className="moveButtonRight" style={{ display: "flex", gap: "10px", "justify-content": "center" }}>
                <FontAwesomeIcon
                    onClick={() => { isApproveEnabled && updateStoryStatus(data.id, REVIEW_READY) }}
                    icon={faCheckCircle}
                    className="fas fa-check"
                    style={{
                        background: "white",
                        color: isApproveEnabled ? "green" : "grey",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: isApproveEnabled ? "pointer" : "not-allowed"
                    }}
                />
                <FontAwesomeIcon
                    onClick={() => { isRejectEnabled && updateStoryStatus(data.id, REJECTED) }}
                    icon={faCancel}
                    className="fas fa-cancel"
                    style={{
                        background: isRejectEnabled ? "red" : "grey",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: isRejectEnabled ? "pointer" : "not-allowed"
                    }}
                />

                <FontAwesomeIcon
                    onClick={() => { isPublishEnabled && updateStoryStatus(data.id, PUBLISHED) }}
                    icon={faArrowUp}
                    className="fas fa-ArrowUp"
                    style={{
                        background: isPublishEnabled ? "blue" : "grey",
                        color: "white",
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px",
                        cursor: isPublishEnabled ? "pointer" : "not-allowed"
                    }}
                />
            </div>
        );
    };

    const deleteStory = (data) => (
        <div className="moveButtonRight" style={{ display: "flex", "justify-content": "center" }}>
            <FontAwesomeIcon
                onClick={() => {
                    if (data.status !== DELETED && window.confirm("Are you sure you want to delete this Stories?")) {
                        updateStoryStatus(data.id, DELETED)
                    }
                }}

                icon={faTrash}
                className="fas fa-trash"
                style={{
                    color: data.status !== DELETED ? "red" : "grey",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: data.status !== DELETED ? "pointer" : "not-allowed"
                }}
            />
        </div>
    );

    const groupStoriesByCreator = (stories) => {
        return stories.reduce((grouped, story) => {
            const creator = story.profile_name || "Unknown Creator";
            if (!grouped[creator]) grouped[creator] = [];
            grouped[creator].push(story);
            return grouped;
        }, {});
    };

    const groupedStories = groupStoriesByCreator(stories);


    return (
        <main className="loginSingupWapper">
            <ToastContainer />

            <div className="storiesPage">
                {stories !== null ? < h3 className="stories mb-4">My Stories</h3> : ""}

                {stories && stories.length === 0 && (
                    <div className="empty-state">
                        <p>No stories available. Start creating your first story now!</p>
                        <Link to={`/create?profile_id=${profileId}`} className="createBtn btn">
                            <span>Create Story</span>
                        </Link>
                    </div>
                )}
                <div className="table-container container overlay">

                    <table className="table" style={{ background: "transparent !important" }}>
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Story Title</th>
                                <th>Status</th>
                                <th>Approve/Reject/Publish</th>
                                <th>Views</th>
                                <th>Likes</th>
                                <th>Read Story</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupedStories).map((creator, groupIndex) => (
                                <React.Fragment key={creator}>
                                    {/* Group Header */}
                                    <tr>
                                        <td colSpan="9" style={{ backgroundColor: '#e6f7ff', fontWeight: 'bold' }}>
                                            Creator: {creator}
                                        </td>
                                    </tr>
                                    {/* Stories under the Group */}
                                    {groupedStories[creator].map((story, index) => (
                                        <tr key={story.id}>
                                            <td>{index + 1}</td>
                                            <td>{story.title}</td>
                                            <td className="td" data-label="Status">
                                                {story.status === REVIEW_READY ? "APPROVED" :
                                                    story.status === PUBLISH_READY ? "PUBLISH READY" : story.status}
                                            </td>
                                            <td className="td" data-label="Approve/Reject/Publish">
                                                <div className="tablecustom">
                                                    {approveDeleteReject(story)}
                                                </div>
                                            </td>
                                            <td>{story.views_count}</td>
                                            <td>{story.likes_count}</td>
                                            <td>
                                                <Link to={`/story/${story.id}`}>View</Link>
                                            </td>
                                            <td>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    style={{ color: 'red', cursor: 'pointer' }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
                );
                {stories && stories.length > 0 && (
                    <div className="pagination">
                        <div className="d-flex justify-content-center createBtn_wapper">
                            <button className="createBtn bigText" onClick={() => setPage(currentPage - 1)} disabled={currentPage === 1}><span>&lt;</span></button>
                        </div>
                        <span className='overlay'>{currentPage}</span>
                        <button className='createBtn bigText' onClick={() => setPage(currentPage + 1)} disabled={stories !== null && stories.length < DEFAULT_PAGE_SIZE}><span>&gt;</span></button>
                    </div>)}
            </div>
        </main>
    )
}

export default ParentStoryList;
