import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Sidemenu from "./Sidemenu";
import { useAuth } from '../../../src/components/AuthContext';

function Profile() {
  const navigate = useNavigate();
  //const token = localStorage.getItem("token");
  //const [isLogin, setIsLogin] = useState(token ? true : false);
  const { isLogin } = useAuth();
  useEffect(() => {
    if (!isLogin) {
      navigate("/");
    }
  });
  return (
    <>
      <div className="porfile">
        <div className="container-fluids">
          <div className="d-lg-flex">
            <Sidemenu />
            <div className="custom">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
