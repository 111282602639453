import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { useAuth } from './AuthContext';
const Header = (params) => {

  const { isLogin } = useAuth();
  const createLink = params.profileId
    ? `/create?profile_id=${params.profileId}`
    : "/create";
  return (
    <section
      data-bs-version="5.1"
      className="header12 cid-u40iIeYW9d"
      id="header12-1q"
    >
      <div className="wapper">
        <div className="banner">
          <div className="container">
            <div className="d-xl-flex justify-content-between align-items-center">
              <div className="banner-text">
                <h1>
                  <span>
                    Infinite <span className="highlight">Stories </span> for
                    Young <span className="highlight">Explorers:</span>
                  </span>{" "}
                  <strong>You Choose, We Create</strong>
                </h1>
                <p>
                  {" "}
                  From dinosaurs to fairy tales: Tailor-made stories to ignite
                  young imaginations.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua,
                  magna aliqua. ipsum is simply dummy text of the printing.
                </p>
                {isLogin ? (
                  <Link
                    to={createLink} className="createBtn btn"
                  >
                    <span> Begin Your Story</span>
                  </Link>
                ) : (
                  <Link to="/login" className="createBtn btn">
                    <span> Begin Your Story</span>
                  </Link>
                )}

              </div>
              <div className="imgwapper">
                <img
                  className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                  src="https://cutesolution.com/html/rainbow2/assets/img/home-font.png"
                  alt="Mobirise Website Builder"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
