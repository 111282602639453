import React, { useEffect, useState } from "react";
import "../userProfile/Editprofile.css";
import { Link, json, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { getuser,updateUser } from "../../api";
import { useAuth } from '../../components/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Editprofile() {
  const token = JSON.parse(localStorage.getItem("token"));
  const [userdata, setuserdat] = useState([]);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const getLoingData = (e) => {
    // e.preventDefault();
    const { value, name } = e.target;
    setuserdat(() => {
      return {
        ...userdata,
        [name]: value,
      };
    });
    // setDitEdit((prevEdit) => ({
    //   ...prevEdit,
    //   [name]: false,
    // }));
  };

  
  useEffect(() => {
       getuser()
      .then((res) => {
        console.log("status code is " + res);
        if (res.status === 200) {
          setuserdat(res.data[0]);
          // console.log(userdata);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          console.log("Token Expired>>>")
           logout()
           navigate('/login'); 
      }
      });
  }, []);
  const updateData = (e) => {
    e.preventDefault();
    console.log(userdata);
    updateUserData()
  };
  //console.log(userdata);
 
  const updateUserData = async () => {
    
    updateUser(userdata).then(
      (response) => {
       console.log("response",response.data);
       toast.success("User data updated successfully!");
      },
      (err) => {
        toast.error("Failed to update user data.");
        console.log("Error>>",err);
        if (err.response && err.response.status === 401) {
          console.log("Token Expired>>>")
      }
       
      }
    );
  };

  return (
    <div>
      <ToastContainer />
      <div className="profileSettings">
        <div className="container">
          <div className="changeYourAccountDetails ">
            <div className="uploadProfile">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <figure>
                    <img
                      src="https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-cartoon-man-avatar-vector-ilustration-png-image_6111064.png"
                      alt=""
                    />
                  </figure>
                  <div className="ProfileInfo">
                    <h4>Upload a new photo</h4>
                    <strong>profile-pic-jpg</strong>
                  </div>
                </div>
                <div className="profileUpdateBtn">
                  <button className="createBtn btn">
                    <span>update</span>
                  </button>
                </div>
              </div>
              <div className="lastseen mb-3">
                <div className="d-flex align-items-center">
                  <i class="fa-regular fa-calendar"></i>
                  <p className="mb-0 ms-2">
                    Joind 5 days ago - last seen 1 day ago
                  </p>
                </div>
              </div>
              <div className="available_credit">
                <h5>Available Credit: $10</h5>
              </div>
            </div>
            <div className="">
              <h3>Manage Account</h3>
              <form className="row g-3">
                <div className="col-md-12">
                  <label for="user_name" className="form-label">
                    User Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="user_name"
                    // value={userdata.first_name}
                    name="user_name"
                    value={userdata.user_name}
                    onChange={getLoingData}
                    disabled
                    // onChange={(e) => setuserdat(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label for="Firstname" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Firstname"
                    // value={userdata.first_name}
                    name="first_name"
                    value={userdata.first_name}
                    onChange={getLoingData}
                    // onChange={(e) => setuserdat(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label for="lastname" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    value={userdata.last_name}
                    onChange={getLoingData}
                    name="last_name"
                  />
                </div>
                <div className="col-md-6">
                  <label for="phone_number" className="form-label">
                    Phone Number
                    {userdata.phone_verified ? (
                      <i class="fa-solid fa-circle-check verify"></i>
                    ) : (
                      <i class="fa-solid fa-exclamation notverify"></i>
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone_number"
                    value={userdata.phone_number}
                    onChange={getLoingData}
                    name="last_name"
                  />
                </div>
                <div className="col-md-6">
                  <label for="inputAddress" className="form-label">
                    Email Address
                    {userdata.email_verified ? (
                      <i class="fa-solid fa-circle-check verify"></i>
                    ) : (
                      <i class="fa-solid fa-exclamation notverify"></i>
                    )}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputAddress"
                    placeholder="example@abc.com"
                    value={userdata.email}
                    name="email"
                    disabled
                    // onChange={getLoingData}
                    // onChange={(e) => setuserdat(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <label for="about" className="form-label">
                    About Me
                  </label>
                  <textarea
                    name="about"
                    id="about"
                    rows="5"
                    value={userdata.about}
                    className="form-control"
                    onChange={getLoingData}
                    placeholder="Type anything..."
                  ></textarea>
                </div>
                <div className="col-12">
                  {/* <button
                    onClick={updateData}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Save
                  </button> */}
                  <button
                    onClick={updateData}
                    type="submit"
                    class="createBtn btn updatedatabtn"
                  >
                    <span>save</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Editprofile;
