import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./menu.css";
import { useAuth } from './AuthContext';
import { useChildProfile } from './../ChildProfileContext';
import { getAllChildProfile, updateChildProfile } from './../api'


const MenuList = () => {
  const { isLogin, logout } = useAuth();
  const [openProfile, setOpenProfile] = useState(false);
  const [scrolltop, setscrolltop] = useState("");
  const { allUser, setAllUser } = useChildProfile();
  const profileRef = useRef(null); // Ref to track the profile div
  const navigate = useNavigate();
  const location = useLocation();

  const isStoryGallery = location.pathname.startsWith("/child/");
  const searchParams = new URLSearchParams(location.search);
  const profile_id = searchParams.get("profile_id");



  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 15) {
        setscrolltop("");
      } else {
        setscrolltop("fixed");
      }
    });
  }, [isLogin]);

  useEffect(() => {
    if (openProfile && allUser.length === 0) {
      getAllChildProfile()
        .then((res) => {
          if (res.status === 200) {
            setAllUser(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [openProfile, allUser, setAllUser]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setOpenProfile(false);
      }
    };


    // Add event listener for detecting clicks outside the profile section
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  // const handleChildSelect = (child, index) => {
  //  // setSelectedChild(child); // Track selected profile
  //   setOpenProfile(false)
  //   const updatedChildData = {
  //     ...child,
  //     is_default: true,
  //   }; 
  //   updateChildProfile(child.id, updatedChildData)
  //   .then((res) => {
  //     // console.log(res.data);
  //     if (res.status === 200) {
  //       console.log("profile update successfully",res.data);
  //       handleLogoutAndRedirect()
  //     }
  //   })
  //   .catch((error) => {
  //     console.error("Error updating profile:", error);
  //     if (error.response && error.response.status === 401) {
  //       console.log("Token expired, logging out...");
  //      handleLogoutAndRedirect()
  //   }
  //   });

  // };

  const handleOnClick = (profile_id) => {
    //navigate(`/child/${profile_id}`, { replace: true });
    window.location.replace(`/child/${profile_id}`);
  };

  const handleLogoClick = (e) => {
    const storedProfileId = localStorage.getItem('profile_id');
    const childProfileOpen = JSON.parse(localStorage.getItem('childProfileOpen')) || false;
    if (isStoryGallery) {
      e.preventDefault();
    } else if (childProfileOpen && storedProfileId) {
      e.preventDefault();
      handleOnClick(storedProfileId);
    }
  };


  const handleLogoutAndRedirect = () => {
    logout();
    navigate('/login');
  };

  return (
    <section className="menu menu4 cid-u40iz8X2iD" id="menu04-1p">
      <nav className={`${scrolltop} navbar navbar-dropdown navbar-fixed-top navbar-expand-lg opacityScroll`}>
        <div className="container navbarWapper">
          <Link to="/" onClick={handleLogoClick}>
            <div className="navbar-brand">
              <img
                className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="Mobirise Website Builder"
              />
            </div>
          </Link>


          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>

          <div className="collapse navbar-collapse opacityScroll" id="navbarSupportedContent">
            <ul className="navbar-nav nav-dropdown navbar-nav-top-padding" data-app-modern-menu="true">
              <li className="nav-item">
                <Link to="/" className="nav-link link icon" onClick={handleLogoClick}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/search-story" className="nav-link link icon">
                  Search
                </Link>
              </li>
            </ul>

            <div className="navbar-buttons mbr-section-btn">
              {!isLogin ? (
                <Link to="/login" className="btn createBtn">
                  <span>Sign In</span>
                </Link>
              ) : (
                <div className="wapper-profile position-relative" ref={profileRef}>
                  <div className="profile" onClick={() => setOpenProfile(!openProfile)}>
                    <img
                      src={
                        allUser?.find((user) => user.is_default)?.profile_image_url ||
                        "https://upload.wikimedia.org/wikipedia/en/thumb/3/3b/SpongeBob_SquarePants_character.svg/1200px-SpongeBob_SquarePants_character.svg.png"
                      }
                      alt="Profile"
                    />
                  </div>

                  {openProfile && (
                    <div className="menu_items">
                      <ul>
                        <li>
                          {isStoryGallery ? (
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent default anchor behavior
                                handleLogoutAndRedirect(); // Call the logout function
                              }}
                            >
                              Switch To Parent
                            </a>
                          ) : (
                            <Link to="/profile">
                              Manage Account
                            </Link>
                          )}
                        </li>

                        {!isStoryGallery && allUser.length > 0 && (
                          <li className="outer_list">
                           <a href="javascript:void(0);"> &lt;  Child Profile</a>
                            <ul className="child_user">
                              {allUser.map((user, index) => (
                                <li
                                  key={index}
                                  //className={user.is_default ? "selected-child" : ""}
                                  onClick={() => handleOnClick(user.id)}
                                >
                                  <a href="javascript:void(0);">{user.profile_name}</a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};


export default MenuList;
