import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddChildDialog from "./AddChildProfile";
import AddChildProfileForm from "./AddChildProfileForm"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addChildProfile, deleteChildProfile, getAllChildProfile, updateChildProfile } from "../../api";
import { useChildProfile } from './../../ChildProfileContext';


function ChildrenProfile() {

  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [childData, setChildData] = useState({
    profile_name: "",
    age_range: "",
    language: "",
    interests: "",
  });

 // const [allUser, setAllUser] = useState([]);
  const { allUser, setAllUser } = useChildProfile();


  useEffect(() => {
    getAllChildProfile()
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200) {
          setAllUser(res.data);
          const profile_id = allUser !== null 
            ? allUser.find(user => user.is_default)?.id 
            : null;  
            localStorage.setItem("profile_id", profile_id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(allUser);

  // Function to handle dialog open
  const handleClickOpen = () => {
    setOpen(true);
    setIsEditing(false);
    setChildData({
      profile_name: "",
      age_range: "",
      language: "",
      interests: "",
    });
  };

  // Function to handle dialog close
  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle input changes
  const handleChange = (e) => {
    const formattedValue = e.target.name === "language" ? capitalizeFirstLetter(e.target.value) : e.target.value;
    setChildData({
      ...childData,
      [e.target.name]: formattedValue,
      // [e.target.name]: formattedValue,
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // Function to handle form submit
  const handleSubmit = () => {
    const isEmpty = Object.values(childData).every(value => value === "");
    const updatedChildData = {
      ...childData,
      language: childData.language || "English",
      age_range: childData.age_range || "2-3",
    };    if (!isEmpty) {
      if (isEditing) {
        //Update API
        updateChildProfile(updatedChildData.id, updatedChildData)
          .then((res) => {
            // console.log(res.data);
            if (res.status === 200) {
              toast.success("The child profile has been updated successfully");
              const updatedList = allUser.map(user =>
                user.id === updatedChildData.id ? res.data : user
              );
              setAllUser(updatedList);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Failed to update the profile.");
          });
      } else {
        addChildProfile(updatedChildData)
          .then((res) => {
            // console.log(res.data);
            if (res.status === 201) {
              toast.success("The child profile has been added successfully");
              setAllUser((prevUsers) => [...prevUsers, res.data]);  // Append new profile to the list
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Failed to add the profile.");
          });
      }
      setOpen(false);  // Close the dialog after submission
    }

  };
  const handleEdit = (id) => {
    console.log("Edit user with ID:", id);
    const userToEdit = allUser.find(user => user.id === id);
    if (userToEdit) {
      userToEdit.language = capitalizeFirstLetter(userToEdit.language);
      setChildData(userToEdit);
      setIsEditing(true);
      setOpen(true);
    }
  };
  const handleDelete = (id, index) => {
    console.log("Delete user with ID:", id);
    // Implement the logic to delete the user profile
    if (window.confirm("Are you sure you want to delete this profile?")) {
      deleteChildProfile(id)
        .then((res) => {
          // console.log(res.data);
          if (res.status === 204) {
            console.log("Index>>>>>", index)
            const updatedList = [...allUser];
            updatedList.splice(index, 1);
            setAllUser(updatedList);
            toast.success("The child profile has been successfully deleted.");
          }
          else {
            toast.error("Failed to delete the profile.");
          }

        })
        .catch((error) => {
          console.log(error);
          toast.error("Failed to delete the profile.");
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="childrenProfile">
        <div className="container">
          <h3 className="mb-2">Children Profile</h3>
          <div class="table-responsive">

            <table class="table table-bordered table-hover">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Profile</th>
                  <th scope="col">Nick Name</th>
                  <th scope="col">Age</th>
                  <th scope="col">Language</th>
                  <th scope="col">interests</th>
                  <th scope="col">Story Published</th>
                  <th scope="col">Story Approved</th>
                  <th scope="col">Story Created</th>
                  <th scope="col">Edit / Delete</th>
                </tr>
              </thead>
              <tbody>
                {allUser.map((user, index) => {
                  return (
                    <tr className={`row${index}`} key={index}>
                      <td>
                        <figure className="child-Profile">
                          <img
                            src="https://upload.wikimedia.org/wikipedia/en/thumb/3/3b/SpongeBob_SquarePants_character.svg/1200px-SpongeBob_SquarePants_character.svg.png"
                            alt=""
                          />
                        </figure>
                      </td>
                      <td>{user.profile_name}</td>
                      <td>{user.age_range}</td>
                      <td>{user.language}</td>
                      <td>{user.interests}</td>
                      {user.language ? <td>0</td> : <td>story count</td>}
                      {/* <td>
                        <Link to={`/update/${user.id}`}>{user.profile_name}</Link>
                      </td> */}
                      <td>0</td>
                      <td></td>
                      <td>
                        {/* Edit and Delete Buttons */}
                        <span
                          style={{ cursor: 'pointer', marginRight: '10px' }}
                          onClick={() => handleEdit(user.id)}
                        >
                          ✏️ Edit
                        </span>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleDelete(user.id, index)}
                        >
                          🗑️ Delete
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* <button className="createBtn btn">
              <span>+ Add Child Profile</span>{" "}
            </button> */}
            {/* Add Child Profile Button */}
            <button className="createBtn btn" onClick={handleClickOpen}>
              <span>+ Add Child Profile</span>
            </button>

            {/* Render the AddChildDialog component */}
            {/* <AddChildDialog
              open={open}
              handleClose={handleClose}
              childData={childData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            /> */}
            {open && (
              <AddChildProfileForm
                childData={childData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                isEditing={isEditing}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChildrenProfile;
