import React, { useEffect } from "react";
import "./ParentsLogin.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { userLogin } from "./../api";
// import Otp from "../components/Otp";
import { useAuth } from './../components/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";



const ParentsLogin = () => {
  const navigate = useNavigate();
  //const token = localStorage.getItem("token");
  //const [isLogin, setIsLogin] = useState(token ? true : false);
  const { isLogin, login } = useAuth();
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
    grant_type: "password",
    client_id: "gY7mwwOPhbuiYuASBXj9Sb6AkejDWz8Ofj9jeeck",
    client_secret:
      "Orouk3ZCjNCkEs2uCo56okOuJwP4IAVSz1datu6u6COvBSxFUMVEfXrTPkHQR1VBj40mBWkhBa2lyEtJ90sljUPau3z39YBxm4s1uivgMFvbiVJXiXMtFY7ARP8kbhuW",
  });

  const [didEdit, setDitEdit] = useState({
    username: false,
    password: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLogin) {
      navigate("/");
    }
    // Check if saved username and password exist in localStorage
    const savedUsername = localStorage.getItem("savedUsername");
    const savedPassword = localStorage.getItem("savedPassword");

    if (savedUsername && savedPassword) {
      setInputValue({
        ...inputValue,
        username: savedUsername,
        password: savedPassword,
      });
      setRememberMe(true);
    }
  }, []);


  const getLoginData = (e) => {
    // e.preventDefault();
    const { value, name } = e.target;
    setInputValue(() => {
      return {
        ...inputValue,
        [name]: value,
      };
    });
    setDitEdit((prevEdit) => ({
      ...prevEdit,
      [name]: false,
    }));
  };

  const Login = (e) => {
    e.preventDefault();
    setError("");

    const {
      username,
      password,
      grant_type,
      client_id,
      client_secret,
    } = inputValue;
    console.log(inputValue);
    // Reset error message before making API call
    setError("");

    userLogin(inputValue).then((res) => {
     // console.log("status code is " + res);
      if (res.status === 200) {
        localStorage.setItem("token", JSON.stringify(res.data.access_token));
       // localStorage.setItem("profile_id", res.data.user.profile_id);

        if (rememberMe) {
          localStorage.setItem("savedUsername", inputValue.username);
          localStorage.setItem("savedPassword", inputValue.password);
        } else {
          localStorage.removeItem("savedUsername");
          localStorage.removeItem("savedPassword");
        }

        navigate("/", { replace: true });
        login(JSON.stringify(res.data.access_token))
        // window.location.reload();
      }
    }).catch((error) => {
      console.log(error);
      const errorMessage =
        error.response && error.response.data.error_description
          ? error.response.data.error_description
          : "Login failed. Please try again.";
      setError(errorMessage); // Set the error message
    });
  };

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <Outlet />
      <section className="loginSingupWapper">
        <div className="container">
          <div className="d-flex align-items-center full-height">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <img
                  className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                  src={`${process.env.PUBLIC_URL}/images/signIn.svg`}
                  alt="Mobirise Website Builder"
                />
              </div>
              <div className="col-12 col-lg-6">
                <div className="signup">
                  <div className="overlay">
                    <h3>
                      <strong>Sign In</strong>
                      Explore Our Enchanted Library
                    </h3>
                    {/* <button onClick={onSubmit}>Submit</button> */}
                    {error && (
                      <div class="alert alert-danger" role="alert">
                        {<p className="mb-0">{error}</p>}
                      </div>
                    )}
                    <form action="#">
                      <div className="inp-wrapper">
                        <input
                          onChange={getLoginData}
                          name="username"
                          type="email"
                          id="email"
                          value={inputValue.username}
                          className="form-control"
                          placeholder="Email Address"
                          maxlength="50"
                        />
                        <div className="error-msg d-none d-flex justify-content-between">
                          <p> Please enter a valid email address </p>
                        </div>
                      </div>

                      <div className="inp-wrapper">
                        <div className="password-container">
                          <input
                            name="password"
                            onChange={getLoginData}
                            value={inputValue.password}
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-control"
                            placeholder="Password"
                            maxLength="50"
                          />
                          <span
                            className="password-toggle-icon"
                            onClick={togglePasswordVisibility}
                          >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                          </span>
                        </div>
                      </div>
                      <div className="error-msg d-none d-flex justify-content-between">
                        <p> Please enter a valid password</p>
                      </div>

                      <div className="imp-wrapper firstnamelastname d-none">
                        <div className="d-flex">
                          <input
                            name="firstname"
                            type="text"
                            id="firstname"
                            placeholder="First Name"
                          />
                          <input
                            name="lastname"
                            type="text"
                            id="lastname"
                            placeholder="Last Name"
                            minLength={8}
                            maxLength={20}
                          />
                        </div>
                      </div>
                      <div className="trems_tags mb-5">
                        <div className="tacbox d-flex align-items-baseline mb-2">
                          <input
                            id="Remember"
                            name="checkbox"
                            type="checkbox"
                            checked={rememberMe}
                            onChange={handleRememberMe}
                          />
                          <label htmlFor="Remember">Remember me</label>
                        </div>

                        <div className="tacbox d-flex">
                          <input
                            id="checkbox"
                            name="checkbox"
                            type="checkbox"
                          />
                          <label htmlFor="checkbox">
                            {" "}
                            Send me newsletten and keep me update on daily basis
                          </label>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center createBtn_wapper">
                        <button
                          onClick={Login}
                          name="submit"
                          type="submit"
                          className="btn btn-black createBtn"
                        >
                          {" "}
                          <Link>
                            <span>Agree &amp; Sign In </span>
                          </Link>
                        </button>
                      </div>
                    </form>
                    <div className="haveAccount">
                      New User
                      <Link to="/registration">Create Account</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ParentsLogin;
