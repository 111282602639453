import React from "react";
import "./Aboutus.css";
import { Link } from "react-router-dom";
const Aboutus = () => {
  return (
    <>
      <section id="aboutUs">
        <div className="container">
          <div className="d-lg-flex justify-content-between align-items-center">
            <div className="aboutustext">
              <strong>WELCOME TO Kids Planet</strong>
              <h2>
                We Learn Smart Way To Build Bright Futute For Your Children
              </h2>
              <p>
                Embark on a magical journey through the realms of imagination
                with our AI-powered story generator designed just for kids like
                you! At Kids Planet, we believe that every child deserves to be
                the hero of their own adventure, and our innovative story
                generator brings your wildest dreams to life with just the click
                of a button.
              </p>
              <p>
                Whether you're a brave adventurer, a curious explorer, or a
                creative storyteller, there's something for everyone at Kids
                Planet: AI Story Generator. Join us on an epic journey of
                imagination and discovery where the only limit is your
                imagination!{" "}
                <Link className="abuLink" to="/login">
                  Sign up
                </Link>{" "}
                now and let the magic begin!
              </p>
              {/* <p>Create characters that are as unique as you are! Whether you want to be a brave knight, a clever inventor, or a friendly dragon, our story generator allows you to customize your characters to suit your imagination.</p> */}
            </div>
            <div className="aboutusImg">
              <img
                className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                src={`${process.env.PUBLIC_URL}/images/aboutus.png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Aboutus;
