import React from "react";
import "../userProfile/Sidemenu.css";
import { NavLink } from "react-router-dom";
import { useAuth } from '../../../src/components/AuthContext';

function Sidemenu() {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    window.location.reload();
    // Navigate("/login");
  };
  return (
    <>
      <div className="sidebar">
        <ul>
          <li className="navLink">
            <NavLink to="/profile">Manage Account</NavLink>
          </li>
          <li className="navLink">
            <NavLink to="children-account">Children Profile</NavLink>
          </li>
          <li className="navLink">
            <NavLink to="/" onClick={handleLogout}>
              Sign out
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidemenu;
