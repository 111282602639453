import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { APPROVED, REJECTED, PUBLISHED, PUBLISH_READY, DEFAULT_PAGE_SIZE, REVIEW_READY, DELETED } from '../constants/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCancel, faArrowUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import './userStories.css';
import { fetchUserStories, changeStatus } from "./../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './../components/AuthContext';


const StoryList = ({ onStoriesLoad, profileId }) => {
    const [stories, setStories] = useState([])
    const [currentPage, setPage] = useState(1);
    const navigate = useNavigate();
    const { logout } = useAuth();



    const fetchStories = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const queryParams = {
            page: currentPage,
            page_size: DEFAULT_PAGE_SIZE
        };
        // const profileId = localStorage.getItem('profile_id');

        fetchUserStories(queryParams, profileId)
            .then(response => {
                setStories(response.data.results);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/login');
                    } else {
                        console.error('API Error:', error.response);
                    }
                } else {
                    console.error('Unexpected Error:', error.message || error);
                }
            });

    };

    useEffect(() => {
        fetchStories();
    }, [currentPage]);

    const updateStoryStatus = (id, status) => {
        changeStatus(id, status).then(response => {
            if (response.status === 200 && stories.length > 0) {
                console.log('updateStoryStatus successfully', response.data.success)
                if (status === PUBLISHED) {
                    toast.success("Your story status has been updated to Published. Congratulations!");
                }
                else {
                    toast.success(`Your story status has been updated to ${status.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ')}`);

                }
                updateStoryUi(id, status);
            }
        }).catch(error => {
            console.error('There was an error!', error);
            toast.error('Failed to update the story status.');
            if (error.response.status === 401) {
                logout();
                navigate('/login');
            }
        });
    };

    const updateStoryUi = (id, newStatus) => {
        setStories((prevStories) =>
            prevStories.map((story) =>
                story.id === id ? { ...story, status: newStatus } : story
            )
        );
    };

    const approveDeleteReject = (data) => {
        const isApproveEnabled = data.status !== PUBLISHED && data.status !== APPROVED && data.status !== PUBLISH_READY && data.status !== REVIEW_READY && data.status !== REJECTED && data.status !== DELETED;
        const isRejectEnabled = data.status !== REJECTED && data.status !== PUBLISHED && data.status !== DELETED;
        const isPublishEnabled = data.status === PUBLISH_READY;

        return (
            <div key={data.status} className="moveButtonRight" style={{ display: "flex", gap: "10px", "justify-content": "center" }}>
                <FontAwesomeIcon
                    onClick={() => { isApproveEnabled && updateStoryStatus(data.id, REVIEW_READY) }}
                    icon={faCheckCircle}
                    className="fas fa-check"
                    style={{
                        background: "white",
                        color: isApproveEnabled ? "green" : "grey",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: isApproveEnabled ? "pointer" : "not-allowed"
                    }}
                />
                <FontAwesomeIcon
                    onClick={() => { isRejectEnabled && updateStoryStatus(data.id, REJECTED) }}
                    icon={faCancel}
                    className="fas fa-cancel"
                    style={{
                        background: isRejectEnabled ? "red" : "grey",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: isRejectEnabled ? "pointer" : "not-allowed"
                    }}
                />

                <FontAwesomeIcon
                    onClick={() => { isPublishEnabled && updateStoryStatus(data.id, PUBLISHED) }}
                    icon={faArrowUp}
                    className="fas fa-ArrowUp"
                    style={{
                        background: isPublishEnabled ? "blue" : "grey",
                        color: "white",
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px",
                        cursor: isPublishEnabled ? "pointer" : "not-allowed"
                    }}
                />
            </div>
        );
    };

    const deleteStory = (data) => (
        <div className="moveButtonRight" style={{ display: "flex", "justify-content": "center" }}>
            <FontAwesomeIcon
                onClick={() => {
                    if (data.status !== DELETED && window.confirm("Are you sure you want to delete this Stories?")) {
                        updateStoryStatus(data.id, DELETED)
                    }
                }}

                icon={faTrash}
                className="fas fa-trash"
                style={{
                    color: data.status !== DELETED ? "red" : "grey",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: data.status !== DELETED ? "pointer" : "not-allowed"
                }}
            />
        </div>
    );

    return (
        <main className="loginSingupWapper">
            <ToastContainer />

            <div className="storiesPage">
                {stories !== null ? < h3 className="stories mb-4">My Stories</h3> : ""}

                {stories && stories.length === 0 && (
                    <div className="empty-state">
                        <p>No stories available. Start creating your first story now!</p>
                        <Link to={`/create?profile_id=${profileId}`} className="createBtn btn">
                            <span>Create Story</span>
                        </Link>
                    </div>
                )}
                {stories && stories.length > 0 && (
                    <div className="table-container container overlay">
                        <table className="table" style={{ background: "transparent !important" }}>
                            <thead className='table thead'>
                                <tr>
                                    <th className="td">Sr. No.</th>
                                    <th className="td">Story Title</th>
                                    <th className="td">Status</th>
                                    {/* {!profileId && <th className="td">Approve/Reject/Publish</th>} */}
                                    {!profileId && (
                                        <th className="td">Approve/Reject/Publish</th>
                                    )}
                                    <th className="td">Views</th>
                                    <th className="td">Likes</th>
                                    <th className="td">Read Story</th>
                                    {!profileId && (
                                        <th className="td">Delete</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {stories !== null ? stories.map((data, index) => (
                                    <tr className="tr" >
                                        <td className="td" data-label="Sr. No.">{index + 1}</td>
                                        <td className="td" data-label="Story Title">{data.title}</td>
                                        {/* <td className="td" data-label="Status">{data.status}</td> */}
                                        <td className="td" data-label="Status">
                                            {data.status === REVIEW_READY ? "APPROVED" :
                                                data.status === PUBLISH_READY ? "PUBLISH READY" : data.status}
                                        </td>

                                        {!profileId && (
                                            <td className="td" data-label="Approve/Reject/Publish">
                                                <div className="tablecustom">
                                                    {approveDeleteReject(data)}
                                                </div>
                                            </td>
                                        )}
                                        <td className="td" data-label="Views">{data.views_count}</td>
                                        <td className="td" data-label="Likes">{data.likes_count}</td>
                                        <td className="td" data-label="Read Story">
                                            {data.media && data.media[0] ? (
                                                <Link to={`/story/${data.id}`}>View</Link>
                                            ) : (
                                                <span style={{ color: 'grey', cursor: 'not-allowed' }}>View</span>
                                            )}
                                        </td>

                                        {!profileId && (
                                            <td className="td" data-label="Delete">
                                                <div className="tablecustom">
                                                    {deleteStory(data)}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    </div>)}
                {stories && stories.length > 0 && (
                    <div className="pagination">
                        <div className="d-flex justify-content-center createBtn_wapper">
                            <button className="createBtn bigText" onClick={() => setPage(currentPage - 1)} disabled={currentPage === 1}><span>&lt;</span></button>
                        </div>
                        <span className='overlay'>{currentPage}</span>
                        <button className='createBtn bigText' onClick={() => setPage(currentPage + 1)} disabled={stories !== null && stories.length < DEFAULT_PAGE_SIZE}><span>&gt;</span></button>
                    </div>)}
            </div>
        </main>
    )
}


export default StoryList;

