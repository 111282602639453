// src/components/AddChildProfileForm.js
import React from 'react';
import './AddChildProfileForm.css';  // Import the CSS file

import { Button, TextField, MenuItem, InputLabel, Select, FormControl } from '@mui/material';


const AddChildProfileForm = ({ childData, handleChange, handleSubmit, handleClose, isEditing }) => {
    return (
        <div className="add-child-profile-form">
            <h4>{isEditing ? "Edit Child Profile" : "Add Child Profile"}</h4>
            <div className="form-row">
                <div className="form-field">
                    <label>Nick Name</label>
                    <input
                        id="nick-name"
                        type="text"
                        name="profile_name"
                        value={childData.profile_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="upload-section">
                <img className="resize-image"
                    src="/images/profile.png"
                    alt="Profile Icon"
                />
                <input className="custom-input"type="file" name="profile_icon" />
            </div>
            </div>
            <div className="form-row">
                <div className="form-field">
                    <label>Age Range</label>
                    <select
                        name="age_range"
                        value={childData.age_range}
                        onChange={handleChange}
                        required
                    >
                        <option value="2-3">2-3</option>
                        <option value="3-5">3-5</option>
                        <option value="5-6">5-6</option>
                        <option value="12-15">12-15</option>
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-field">
                    <label>Language</label>
                    <select
                        name="language"
                        value={childData.language}
                        onChange={handleChange}
                    >
                        <option value="English">English</option>
                        <option value="Spanish">Spanish</option>
                        <option value="French">French</option>
                        <option value="Hindi">Hindi</option>
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-field">
                    <label>Interests</label>
                    <textarea
                        name="interests"
                        value={childData.interests}
                        onChange={handleChange}
                        rows="2"
                    />
                </div>
            </div>
            
            <div className="form-row">
                <button onClick={handleSubmit} className="btn btn-success">
                    {isEditing ? "Update" : "Save"}
                </button>
                <button onClick={handleClose} className="btn btn-danger">
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default AddChildProfileForm;
