import React, { createContext, useState, useContext } from 'react';

const ChildProfileContext = createContext();

export const useChildProfile = () => {
  return useContext(ChildProfileContext);
};

export const ChildProfileProvider = ({ children }) => {
  const [allUser, setAllUser] = useState([]);

  return (
    <ChildProfileContext.Provider value={{ allUser, setAllUser }}>
      {children}
    </ChildProfileContext.Provider>
  );
};
