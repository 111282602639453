import React, { useEffect } from "react";
import { fetchStories,fechUserStoryLikeView } from "./../api";
import StoryCard from "./storyCard";
import Header from "./header";
import Features from "./features";
import config from "../../src/config";
import './storyGallery.css'
import Aboutus from "../components/Aboutus";
import { useAuth } from './AuthContext';
import StoryList from "../pages/userStories"
import { useParams } from 'react-router-dom'; 



const ChildStoryGallery = () => {
  const baseAPIUrl = config.API_BASE_URL;
  const [stories, setStories] = React.useState([]);
  const [storyInfo, setStoryInfo] = React.useState([]);
  const [userLikesViews, setUserLikesViews] = React.useState([]);

  const { isLogin } = useAuth();
  const { profile_id } = useParams(); 

  const onSuccess = (response) => {
    setStories(response.data.results);
    const newInfo = response.data.results.map((story) => {
      return {
        id: story.id,
        genre: story.genre,
        number_of_chapters: story.number_of_chapters,
        title: story.title,
        media: story?.media[0]?.image_file_url
      };
    });
    setStoryInfo(newInfo);
  }
  useEffect(() => {
    if (profile_id) {
      localStorage.setItem('childProfileOpen', JSON.stringify(true));
      localStorage.setItem("profile_id", profile_id);
    }
  }, [profile_id]);

  useEffect(() => {
    const getPosts = async () => {
      await fetchStories().then((data)=> {
        onSuccess(data)
      }, (error)=> {
        setStories([]);
      });
    };

    getPosts();
  }, []);

  useEffect(() => {
    if (isLogin) {
      // Call `fechUserStoryLikeView` only once if the user is logged in
      fechUserStoryLikeView()
        .then((res) => {
          if (res.status === 200) {
            console.log("User's like/view data:", res.data);
            setUserLikesViews(res.data);
            
          }
        })
        .catch((error) => {
          console.error("Error fetching like/view data:", error);
        });
    }
  }, [isLogin]);

  return (
    <>
      <Header profileId={profile_id}/>
      
         {/* {isLogin && <StoryList  class="modal-body selectChild" />} */}
         <StoryList profileId={profile_id} onStoriesLoad={(data) => console.log('Stories Loaded:', data)} />

    <Aboutus />
            <section
        data-bs-version="5.1"
        className="features4 start cid-u40iKFICvC"
        id="features04-1r"
      >
        <div className="container"> 
          <div className="justify-content-center">
            <div className="col-12 content-head">
              <div className="mbr-section-head">
                <h4 className="exploreSectionHeadding">
                  Explore Our Enchanted Library of<strong> Adventures</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="d-md-flex flex-wrap adventures">
            {stories &&
              stories
                .sort((a, b) => (a.id > b.id ? -1 : 1))
                .slice(0, 8)
                .map((story, index) => {
                  const userStoryData = userLikesViews.find(data => data.story_id === story.id) || {};
                  return (
                    <StoryCard
                      story={story}
                      key={index}
                      baseAPIUrl={baseAPIUrl}
                      isLiked={userStoryData.like}
                      isViewed={userStoryData.view}
                    />
                  );
                })}
          </div>
        </div>
      </section>
      <Features storyInfo={storyInfo} baseAPIUrl={baseAPIUrl}/>
    </>
  );
};

export default ChildStoryGallery;
